@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #56595c;
  font-size: 14px;
  font-family: open sans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}

/* other style */
a {
  text-decoration: none !important;
}

h1 {
  font-size: 3rem;
}

/* Header and Navbar */
.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

nav.desktop-nav {
  margin-left: 40%;
  margin-top: 26px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.top-lt-info {
  align-items: center;
  display: flex;
  margin-left: 71%;
  gap: 7;
  gap: 14px;
  padding: 10px;
}

/* header-phone */

.header-phone a {
  color: #05044D;
}

.text-center.mt-5.mb-5.hero-banner-sub-text {
  margin-top: 33px !important;
}

img.homeaboutimage.jumping-animation {
  width: 70%;
  margin-top: 0%;
  margin-left: 25%;
}



/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  text-transform: uppercase;
  font-weight: 600;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
  margin-top: -11px;
  height: 40px;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  margin-left: -7px;
}

.desktop-nav .menu-items a:hover {
  color: #050494;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav button:hover {
  color: #050494;
}

.desktop-nav .menu-items>a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1.5rem;
  font-size: 17px;

}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: 23px;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;

}

.desktop-nav .dropdown.show {
  display: block;
  color: #000;
  min-width: 300px;
  /* border-bottom: 1px solid #000;  */
}

.desktop-nav .dropdown.show a {
  text-align: justify;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  color: #000;

  /* height: 50vh;
    overflow-y: scroll; */

}

.desktop-nav .dropdown .dropdown-submenu .menu-items>a {
  padding: 1rem;
  height: 40px;
  /* border-bottom: 1px solid #000; */
}

.desktop-nav .dropdown .dropdown-submenu .menu-items>a:hover {
  color: #050494;
}

.desktop-nav .dropdown .dropdown-submenu .menu-items>a:not(:last-child) {
  border-bottom: none;
}


.desktop-nav .dropdown .menu-items button {
  text-align: left;
  padding: 15px;
  font-size: 17px;
  /* border-bottom: 1px solid #000; */
}

.mobile-nav {
  display: none;
}

.hero-banner-sub-text-top {
  display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .hero-banner-sub-text-top {
    display: block;
    margin-top: -27% !important;
    margin-bottom: 46% !important;
  }

  .home-subtext {
    font-size: 16px !important;
    font-weight: 600;
  }

  .home-subtitle {
    font-size: 28px;
    margin-top: 50px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .about-list li {
    margin-left: 30px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0% !important;
  }

  img.homeaboutimage.jumping-animation {
    margin-top: -5%;
    margin-left: 10%;
    margin-bottom: 20px;
  }

  .card {
    height: 30vh !important;
  }


  .card-wrapper {
    height: 20vh !important;
    margin-bottom: 30% !important;
  }

  .card .card-body .desc {
    font-size: 12px !important;
  }

  .support-container .support-card {
    margin-bottom: 15% !important;
    margin-left: 5% !important;
  }

  .react-datepicker__month-container {
    width: 230px !important;
  }

  .vision-card {
    width: 200px !important;
    /* display: inline-block; */
    margin-left: 103px !important;
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }

  .what-us-text p {
    padding: 12px !important;
  }

  .why-us {
    margin-left: 0 !important;
  }

  .why-us .why-card {
    margin-left: 56px !important;
    margin-bottom: -20% !important;
  }

  .hm-services-sec {
    padding-bottom: 91px !important;
  }

  .hm-About-img img {
    width: 100% !important;
    margin-top: 40px;
    margin-left: 0 !important;
  }



  /* .header-phone a{
    display: none;
  } */
  :where(.css-1qfezbu).ant-carousel {
    display: none !important;
  }

  .nav-area {
    justify-content: space-between;
    background: #fff !important;
    height: 0;
    margin-left: -36px;
  }

  .responsive-header {
    color: #000 !important;
    height: 9vh !important;
  }

  .header-logo {
    width: 97px !important;
    margin-top: 46% !important;
    margin-left: 14px;
  }

  .banner-image {
    display: block;
    width: 100%;
    height: 220px !important;
  }

  .header-phone {
    display: none;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-nav .mobile-nav__menu-button {
    color: #0a0909;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    margin-top: 28px;
    margin-left: 85%;
  }

  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items>a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }

  .mobile-nav .dropdown .dropdown-submenu {
    height: 20vh;
    overflow-y: scroll;
  }

  .text-center.mt-5.mb-5.hero-banner-sub-text {
    margin-top: -5px !important;
    display: none;
  }

}


/* hero */
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  /* Change the background color as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add shadow for a nice effect */
}

.home-subtext {
  font-size: 1.8rem;
  font-weight: 600;
}

.desktop-view-hero-btn {
  display: none;
}

/* about-section */
.hm-About-sec {
  display: block;
  clear: both;
  position: relative;
  background: #fff;
  padding-top: 80px;
  padding-bottom: 20px;
}

.hm-About-sec p {
  font-size: 20px;
  margin: 10px;
  text-align: justify;
  margin-top: 28px;
  line-height: 42px;
}

.hm-About-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #050521;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

.hm-About-sec h2 span {
  color: #05044D;
  font-weight: 700;
}

.hm-About-sec h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: #05044D;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}

.hm-About-img img {
  width: 80%;
  margin-left: 15%;
}



.btn-red {
  color: #fff;
  background-color: #05044D;
  border-color: #05044D;
  display: inline-block;
  font-size: 16px;
  padding: 8px 35px;
  line-height: 1.5;
  margin-top: 20px;
  text-decoration: none;
}

.btn-red:hover {
  background-color: #05044D;
  border-color: #05044D;
  color: #fff;
  text-decoration: none;
}

.aboutimage {
  margin-left: 15%;
  width: 70%;
  margin-top: 45px;
}



.about-list li {
  list-style-type: none;
}

.about-list li {
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 136%;
  color: #0C0649;
  margin-bottom: 25px;
  letter-spacing: 10px;
  cursor: pointer;
}

.animated-list-item {
  z-index: -1;
  height: 60px;
  width: 0%;
  cursor: pointer;
}

.animated-list-item:hover {
  color: #CFD6DD;
}



/* service-section */
.hm-services-sec {
  display: block;
  clear: both;
  position: relative;
  background: #e5dddd3b;
  padding-top: 107px;
  padding-bottom: 20px;
}

.navItenCon {
  display: flex;
  margin-left: 30px;
  gap: 50px;
  margin-bottom: 40px;
}

.navItenCon .card h2 {
  font-size: 20px;
}

.hm-services-title {
  margin-bottom: 40px;
  text-align: center;
}

.hm-services-title p {
  font-size: 16px;
  margin: 0;
  text-align: justify;
}

.hm-services-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #050521;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

.hm-services-sec h2 span {
  color: #05044D;
  font-weight: 700;
}

.hm-services-sec h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: #05044D;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}

/* card */
.card-wrapper {
  height: 45vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.card {
  position: relative;
  background: white;
  /* border: 1px solid #05044D; */
  box-shadow: 1px 1px 4px rgba(19, 39, 67, 0.3);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* margin: 10px; */
  height: 40vh;
}

.card:hover,
.card.hover {
  border-color: #05044D;
}

.card:hover .card-head,
.card:hover .card-body,
.card.hover .card-head,
.card.hover .card-body {
  transform: translateY(0);
}

.card.hover .card-head-navcard {
  transform: translateY(0);
}

.card .card-head {
  transform: translate(0%, 85%);
  transition: 0.3s ease all;
}

.card .card-head svg {
  width: 50px;
  height: 50px;
  margin-left: 35%;
}

.card .card-head svg path {
  fill: #05044D;
}

.card .card-head h3 {
  padding: 0 1rem;
  text-align: center;
  color: grey;
  text-transform: uppercase;
  font-size: 20px;
  margin-left: -26px;
}

.card .card-body {
  transform: translateY(100%);
  transition: 0.3s ease all;
}

.card .card-body .desc {
  padding: 0.5rem 1rem;
  order: 99;
  text-align: center;
}


/* Navcard */
.nav-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 90%;
  max-width: 1000px;
  /* margin: 10vh auto; */
}

.navcard {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 300px;
}

.information {
  background-color: #fff;
  padding: 1.5rem;
  height: 50vh;
}

.information .tag {
  display: inline-block;
  background-color: #f1eeff;
  color: #05044D;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  border-radius: 6px;
}

.information .tag+* {
  margin-top: 1rem;
}

.information .title {
  font-size: 1.5rem;
  color: #141b22;
  line-height: 1.25;
}

.information .info {
  color: #a0a0a0;
}

.information .info+* {
  margin-top: 1.25rem;
}

.information .button {
  font: inherit;
  line-height: 1;
  background-color: transparent;
  border: 2px solid #05044D;
  color: #05044D;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.information .button:hover,
.information .button:focus {
  background-color: var(--c-indigo);
  color: var(--c-white);
}

@media only screen and (max-width: 767px) {
  .card {
    width: 100%;
  }
}

/* News Section  */
.news-block-sec {
  display: block;
  clear: both;
  position: relative;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background: #343a85;
  padding-top: 40px;
  padding-bottom: 30px;
}

.news-block-sec:before {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
}

.news-block-sec h2 {
  text-align: center;
  line-height: 36px;
  padding-bottom: 30px;
  font-size: 30px;
  margin: 0;
  color: #fff;
}

.news-block-sec h2 span {
  color: #fff;
  font-weight: 600;
  font-size: 36px;
}

.news-item-box {
  margin-bottom: 30px;
  padding: 20px 20px;
  background-color: #fff;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s;
}

.news-item-box h3 {
  font-size: 24px;
  margin: -20px -20px 30px;
  background: #050521;
  padding: 10px 0;
  color: #fff;
  position: relative;
  text-align: center;
}

.news-item-box h3:after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #050521;
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 100%;
}

.duedate td {
  border-bottom: 1px dashed #ccc;
  padding: 5px
}

.duedate td table td {
  border: 0;
  padding-right: 20px;
  font-weight: bold;
}

.duedate .dtblgo {
  border-bottom: 1px dashed #ccc;
  padding: 5px
}

.duedate .datrtl {
  font-weight: bold;
}

.duedate .dtblgo:last-child {
  border: 0;
}

#ctl00_DataListGovind a {
  font-weight: bold;
  padding-right: 25px;
  font-size: 12px;
  color: #1b396b;
}

.news_blog {
  border-bottom: 1px dashed #ccc;
  color: #000;
  display: block;
  padding: 5px 0 5px;
}

.arrownews {
  padding: 5px 0 5px;
  display: block;
}

.news2d a {
  border-bottom: 1px dashed #ccc;
  color: #000;
  display: block;
  padding: 5px 0px 5px 0px;
  font-weight: normal;
}

.hedingnews,
a.hedingnews {
  display: block;
  font-weight: bold;
  border: 0;
  padding: 0;
}

.boxstylenew_call .alternate {
  padding: 5px;
}

.boxstylenew {
  float: left;
  width: 100%;
  padding-top: 13px;
}

.boxstylenew_call {
  width: 32.5%;
  margin-right: 5px;
  float: left;
  border: 1px solid #2b4081;
  height: 332px;
}

/* support-section */
.support-section {
  padding: 20px 0;
  margin: 4%;
}

.support-section h1 {
  text-align: center;
  font-size: 35px;
  color: #4169E1;

}

/* Support card */
.hm-support-sec {
  display: block;
  clear: both;
  position: relative;
  background: #fff;
  padding-top: 80px;
  padding-bottom: 20px;
}

.hm-support-title p {
  font-size: 16px;
  margin: 10px;
  text-align: justify;
  margin-top: 28px;
}

.hm-support-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #050521;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

.hm-support-sec h2 span {
  color: #05044D;
  font-weight: 700;
}

.hm-support-sec h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: #05044D;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}

.grid-support-container>div {
  margin-top: 40px;
  display: inline-flex;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-left: 10%;
}

/* .support-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
  gap: 20px;

} */

.support-container .support-card {
  position: relative;
  /* min-width: 320px; */
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
  /* margin: 30px; */
  transition: 0.5s;
  width: 300px;
}

.support-container .support-card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #f4f4f6;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

/* .support-container .support-card .box:hover {
  transform: translateY(-50px);
} */

.support-container .support-card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.support-container .support-card .box .content {
  padding: 20px;
  text-align: center;
}

.support-container .support-card .box .content svg {
  position: absolute;
  top: 12px;
  right: 114px;
  font-size: 35px;
}

.support-container .support-card .box .content h3 {
  font-size: 18px;
  color: #000;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
  margin-top: 28%;
  text-transform: uppercase;
  font-weight: 600;

}

.support-container .support-card .box .content p {
  font-size: 15px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
  color: gray;
  text-align: -webkit-match-parent;
}



/* .supportcard {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.support-Limited-card {
  width: 100%;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 50vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 30px;
}

.support-Limited-card .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: #050494;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.support-Limited-card:hover {
  border: 1px solid #05044D;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.supportcard .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: #050494;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.supportcard .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: grey;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.supportcard:hover {
  height: 420px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  min-width: 318px;
}

.supportcard .icon {
  font-size: 40px;
}

.supportcard .icon :hover {
  margin-top: -10%;
}

.supportcard:hover .info {
  height: 90%;
}

.supportcard:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 80px;
  height: 100%;
}

.supportcard:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
} */


/* footer */

footer {
  background: #050521;
  color: #f9f9f9;
  display: block;
  clear: both;
  position: relative;
  padding-top: 10px;
}

.foote-link h2 {
  color: #f9f9f9;
  margin: 0 0 24px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
}

.foote-link {
  padding-top: 40px;
  padding-bottom: 0px;
  display: block;
  clear: both;
}

.foote-link ul.foot-link-list {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  font-size: 14px;
}

.foote-link ul.foot-link-list li {
  position: relative;
  color: #f9f9f9;
}

.foote-link ul.foot-link-list li a {
  text-transform: capitalize;
  line-height: 26px;
  color: #f9f9f9;
  padding: 3px 0;
  display: block;
  transition: all 0.3s;
  position: relative;
  padding-left: 20px;
  text-decoration: none;
}

.foote-link ul.foot-link-list li a:before {
  content: '\2192';
  position: absolute;
  left: 0;
  top: 3px;
  font: 400 18px/24px 'FontAwesome';
  color: #fff;
  transition: .33s all ease;
  margin-left: -6px;
}

.foote-link ul.foot-link-list li a:hover:before {
  left: 4px;
}

.foote-link ul.about-list li .ab-pd {
  line-height: 20px;
  overflow: hidden;
  display: block;
  color: #000;
}

.foote-link ul.about-list li.call-mail a {
  color: #f9f9f9;
}

.foote-link .form-control {
  height: 43px;
  margin-bottom: 15px;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  padding-left: 10px;
  background: #fff;
}

.btn-join {
  background: #ccc;
  border-radius: 0;
  font-size: 16px;
  text-transform: uppercase;
  height: 43px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  cursor: pointer;
}

.btn-join:hover,
.btn-join:focus {
  background: #001f2e;
  color: #fff;
}

.foote-link .form-control::-moz-placeholder {
  color: #222327;
  opacity: 1;
}

.copy-right {
  padding-top: 25px;
  padding-bottom: 20px;
  margin-top: 30px;
  background: #050521;
  border-top: 1px solid #394654;
  display: block;
  clear: both;
}

.copy-right p {
  margin-bottom: 0;
  color: #f9f9f9;
  font-size: 15px;
  margin-left: 10%;
}

.copy-right a {
  color: #f9f9f9;
  padding-left: 10px;
}

.call-ad {
  padding: 0;
  margin: 0 0 30px;
}

.call-ad li {
  display: block;
  margin-bottom: 10px;
}

.call-ad li a {
  color: #f9f9f9;
  text-decoration: none;
}



/* CommonBannerImage */
.text-center {
  text-align: center !important;
  text-transform: uppercase;
  color: #05044D;
  font-size: 40px;
}




/* blog */
.grid-whyus-container>div {
  margin-top: 20px;
  display: inline-flex;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-left: 5%;
}

.blog-1 {
  background: #fff;
  padding: 29px 37px 32px 32px;
  margin-top: 38px;
  border: none;
  border-radius: 30px;
  height: 70vh;
}

.blog-1 h5 {
  text-align: center;
  margin-bottom: 20px;
  color: #05044D;
  padding: 10px;
  text-transform: uppercase;
}

.blog-2 h5 {
  text-align: center;
  margin-bottom: 20px;
  color: #05044D;
  padding: 10px;
  text-transform: uppercase;
}

.blog-1 p {
  padding: 10px;
  text-align: justify;
  color: gray;
}

.blog-2 p {
  padding: 10px;
  text-align: justify;
  color: gray;
}

.blog-1:hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.blog-2:hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.blog-2 {
  background: #fff;
  padding: 29px 37px 32px 32px;
  margin-top: 38px;
  border: none;
  border-radius: 30px;
  /* margin-top: -58px; */
}

.help-with-sec {
  background: #fff;
  /* padding: 5%; */
}



/* contact from */

.hm-contactus-sec {
  color: #333;
  display: block;
  clear: both;
  position: relative;
  padding-top: 50px;
  padding-bottom: 0px;
  overflow: hidden;
  height: 85vh;
}

.hm-contactus-sec-two {
  background: #bab9b930;
  color: #333;
  display: block;
  clear: both;
  position: relative;
  padding-top: 20px;
  padding-bottom: 0px;
  overflow: hidden;
}


.hm-contactus-title {
  padding-bottom: 80px;
}

.hm-contactus-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #05044D;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

.hm-contactus-sec h2 span {
  color: #05044D;
  font-weight: 700;
}

.hm-contactus-sec h2:after {
  content: '';
  display: block;
  width: 100px;
  height: 3px;
  background: #05044D;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}

.hm-contactus-title p {
  margin-bottom: 0;
  line-height: 2;
  font-size: 14px;
  text-align: center;
}

.hm-contactus-sec .group {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

input.form-control,
textarea.form-control {
  font-size: 12px;
  font-style: italic;
  padding: 10px;
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  background-color: transparent;
  color: #222;
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  outline: none !important;
  margin-bottom: 20px;
}

.btn-send {
  color: #fff;
  background-color: #05044D;
  border-color: #05044D;
  height: 60px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btn-send:hover {
  background-color: #05044D;
  border-color: #05044D;
  color: #fff;
}



.bg-athens-gray-inner:before {
  content: '';
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200vw;
  z-index: -1;
  background: #f2f2f5;
  height: 94vh;
}

.contact-from {
  margin-left: 29px !important;
}

.contact_form {
  padding: 35px 20px;
  background: #fff;
  position: relative;
  z-index: 3;
  margin-top: 64px;
}

.address p {
  text-align: start;
  margin-bottom: 10px;
  margin-top: 18px;
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px !important;
}

.address ul li {
  list-style: none;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 20px;
}

/* .map-center{
  margin-top: -20px;
} */

/* countdown */

.countdown {
  text-align: center;
}

.countdown-text {
  margin-top: 20%;
  font-size: 3rem;
  color: #333;
}

.countdown-info {
  margin-top: 1rem;
  color: #777;
}

.countdown-timer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 48%;
  border: 6px solid #5cb85c;
  border-top-color: transparent;
  animation: countdown-animation 5s linear forwards;
}

@keyframes countdown-animation {
  to {
    transform: rotate(360deg);
  }
}


/* payment */
.withgst,
.withoutgst {
  margin-left: 25%;
}


/* common card */
.card-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.card-container a {
  text-decoration: none;
}



.card1 h3 {
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  text-align: center;
  margin-top: 4px;
}

.card1 p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  text-align: justify;
}

.card1 p.small {
  font-size: 14px;
  text-align: justify;
  margin-top: 10%;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #05044D;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
}

.card1 {
  display: block;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 15%;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 280px;
  width: 100%;
  margin-top: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 25px;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #05044D;
  height: 60px;
  width: 32px;
  border-radius: 35px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.mobile-view-hero-btn {
  display: none;
}


/* Responsive */

@media (max-width: 450px) {
  .topbar {
    display: none;
  }

  nav.bg-body-tertiary.topbar-nav.navbar.navbar-expand-md.navbar-light {
    display: none;
  }

  .text-white {
    text-align: center;
    color: #fff;
  }

  .text-white p {
    margin: 0;
    margin-top: 26px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .text-white .mobile-view-hero-btn {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #fff;
    font-size: large;
    font-weight: 700;
    display: block;
    margin-left: 5rem !important;
    background: transparent;
  }

  .text-white .mobile-view-hero-btn:hover {
    color: #fff;

  }

  .hm-about-sec h2 {
    font-size: 23px;
  }

  .hm-about-sec h2:after {
    background: none;
  }

  .hm-about-sec p {
    font-size: 20px;
    margin: 7px;
    text-align: justify;
    line-height: 40px;
    padding: 0;
  }

  .about-list {
    list-style: none;
    padding: 0;
    margin-top: -30%;
  }

  ul.about-list a {
    text-decoration: none !important;
  }

  .about-list li {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 7px;
  }

  .aboutimage {
    margin-top: 42px;
    margin-bottom: 19px;
    border-radius: 30px;
    margin-left: 0;
    width: 100%;
  }

  .loader-text {
    font-size: 1.5rem !important;
  }

  .circle {
    margin-top: 55% !important;
  }

  .hm-services-sec {
    padding-top: 40px;
  }

  .hm-services-sec h2 {
    font-size: 23px;
  }

  .hm-services-sec h2:after {
    background: none;
  }

  .card-container .card a:hover {
    transform: translate(2px, -10px);
    border-color: #05044D;
    border-radius: 15px;
    height: 340px;
  }

  .card--display {
    margin-top: 46px;
  }

  .card-container .card a {
    padding: 15px;
  }

  .supportcard .icon {
    font-size: 47px;
    margin-top: 74px;
  }

  .supportcard:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
    margin-top: -57%;
  }

  .card-container {
    margin-left: 0px;
  }

  .grid-support-container>div {
    margin-top: 39px;
    display: flow;
    margin-left: 4%;
  }

  .supportcard {
    margin-bottom: 30px;
  }

  .hm-services-title p {
    font-size: 16px;
    padding: 19px;
    text-align: justify;
  }

  .blog-1 {
    height: 120vh;
  }

  .hm-services-sec p {
    padding: 20px;
    text-align: justify;
    font-size: 16px;
    line-height: 30px;
  }

  .supportcard:hover {
    height: 470px !important;
  }

  .overlay {
    left: -11px;
  }

  .support-Limited-card {
    margin-left: 0px;
  }

  /* img.img-fluid.QuickEnquiryimg {
  margin-left: 0% !important;
} */
}

.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: -19px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #fafcfe;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: -19px;
}

.card1 {
  margin: 0px;
  margin-bottom: 20px;
  height: 370px;
}


/* Loader page */

.patterns {
  height: 100vh;
}

svg text {
  letter-spacing: 10px;
  /* Adjust letter-spacing for smaller screens */
  font-size: 45px;
  /* Use viewport units for responsive font size */
  font-weight: 400;
  stroke: #0C0649;
  stroke-width: 5;
  /* Adjust stroke width for smaller screens */
  text-transform: uppercase;
  /* animation: textAnimate 5s infinite alternate; */
}

/* Define media query for screens with a maximum width of 450px */
@media only screen and (max-width: 450px) {
  svg text {
    letter-spacing: 1px;
    font-size: 12px;
    stroke-width: 1;
  }
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: hsl(195, 10%, 84%);
  }

  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffset: -20%;
    fill: hsla(0, 88%, 50%, 0);
  }
}


.circle {
  height: 200px;
  border-radius: 50%;
  background: transparent;
  position: relative;
  animation: pulse 2s infinite;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


.loader-text {
  text-align-last: center;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
}



/* Whyus Card */
.why-card {
  flex: 1;
  margin: 10px;
}

.why-us .why-card {
  position: relative;
  cursor: pointer;
}

.why-us .why-card .face {
  width: 200px;
  height: 200px;
  transition: 0.5s;
}

.why-us .why-card .face.face1 {
  position: relative;
  background: #05044D;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  color: #fff;
}

.why-us .why-card:hover .face.face1 {
  background: #E35C18;
  transform: translateY(0);
}

.why-us .why-card .face.face1 .content {
  transition: 0.5s;
}

.why-us .why-card:hover .face.face1 .content {
  opacity: 1;
}

.why-us .why-card .face.face1 .content img {
  max-width: 100px;
}

.why-us .why-card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
}

.why-us .why-card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transform: translateY(-100px);
  text-align: center;
}

.why-us .why-card:hover .face.face2 {
  transform: translateY(0);
}

.why-us .why-card .face.face2 .content p {
  text-align: center !important;
  ;
}

.why-us .why-card .face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #05044D;
  padding: 5px;
  border: 1px solid #333;
}

.why-us .why-card .face.face2 .content a:hover {
  background: #333;
  color: #fff;
}

.what-us-text p {
  font-size: 16px;
  margin: 0;
  text-align: justify;
  padding: 20px;
  letter-spacing: 1px;
  line-height: 30px;
}




@media screen and (max-width: 768px) {
  .why-us {
    flex-wrap: wrap;
  }

  .why-card {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .why-card {
    flex-basis: calc(100% - 20px);
  }

  .why-us .why-card {
    margin-left: 0;
  }

  .why-us .why-card .face {
    width: 280px;
  }
}

/* tax-calculator */


.gst-calculator {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input,
.input-group input[type="radio"] {
  margin-right: 10px;
}

.radio-group div {
  display: flex;
  align-items: center;
}

.input-group input[type="number"],
.input-group input[type="radio"] {
  appearance: none;
}

.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group input[type="number"] {
  -moz-appearance: textfield;
}

.input-group input[type="number"]::-webkit-outer-spin-button,
.input-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group input[readonly] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}


/* call schedule */
.container-calendar {
  box-shadow: 0 15px 30px 1px rgb(178, 177, 177);
  background: #fff;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 5em auto;
  height: 350px;
  width: 50%;
}

@media screen and (max-width: 960px) {
  .container-calendar {
    display: flex;
    flex-direction: column;
    /* Stack items vertically by default */
    align-items: center;
    padding: 20px;
    width: 90%;
    height: 67vh !important;
  }

  .container-calendar .product-details h1 {
    font-size: 15px !important;
    margin-top: -18px !important;
    margin-left: 1px !important;
    text-align: center !important;
  }

  .product-details {
    width: 100% !important;

  }

  .product-image {
    width: 100% !important;
    margin-top: -80% !important;
  }

  .container-calender .product-details p {
    font-size: 10px !important;
    margin-top: -20px !important;
  }
}

.product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 30px;
  height: 100%;
  float: left;
  width: 50%;
}

.container-calendar .product-details h1 {
  display: inline-block;
  position: relative;
  font-size: 29px;
  color: #05044D;
  margin: 0;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 16px;

}



.container-calender .product-details p {
  text-align: center !important;
  font-size: 20px;
  color: gray;
  margin-top: 20px;
}

.product-image {
  transition: all 0.3s ease-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 100%;
  float: right;
  width: 45%;
  display: inline-block;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 2.5rem !important;
}



/* .calender {
  padding: 50px;
  margin: 24px;
  border-radius: 20px;
  box-sizing: border-box;
} */

.left-side {
  padding: 50px;
  margin: 24px;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
}

.react-datepicker__month-container {
  width: 337px;
  height: 50vh;
  box-sizing: border-box;
  border: none !important
}

@media (max-width: 768px) {

  .calender,
  .left-side {
    padding: 30px;
    margin: -1px;
  }

  .react-datepicker__month-container {
    height: 40vh;
    width: 300px;
    width: 280px !important;
  }
}

@media (max-width: 480px) {

  .calender,
  .left-side {
    padding: 20px;
    margin: 12px;
  }

  .react-datepicker__month-container {
    height: 40vh;
    width: 280px !important;
  }
}




.booking-form p {
  text-align: center;
  font-size: medium;
  font-weight: 700;
  text-transform: uppercase;
}

.booking-form {
  padding: 2rem 1rem 0;

}

.booking-form .input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
  margin-top: 30px;
}

.booking-form .input+.input {
  margin-top: 1rem;
}

.booking-form .input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.booking-form .input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}

.booking-form .input-field:focus,
.input-field:valid {
  outline: 0;
  border-bottom-color: #05044D;
}

.booking-form .input-field:focus+.input-label,
.input-field:valid+.input-label {
  color: #05044D;
  transform: translateY(-1.5rem);
}


.booking-form .action {
  margin-top: 1rem;
}

.booking-form .action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  background-color: #05044D;
  border-radius: 6px;
  color: #FFF;
  border: 0;
  margin-top: 1.5rem;
}

.booking-form .action-button:focus {
  outline: 0;
}


.Schedule-call {
  border: 1px solid #000;
  background: transparent;
  border-radius: 20px;
  margin-top: 8px;
}

.Schedule-call:hover {
  background: transparent;
  color: #fff;
  border: 1px solid #05044D;
}



/* vision-card */

.vision-card {
  width: 350px;
  display: inline-block;
  margin-left: 50px;
  margin-top: 50px
}

.vision-card .card {
  position: relative;
  overflow: hidden;
  width: 350px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 290px;
  cursor: pointer;
}

.vision-card .content .title {
  margin-top: 21px;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
}



.vision-card .card .layer {
  z-index: 1;
  position: absolute;
  top: calc(100% - 2px);
  height: 100%;
  width: 100%;
  left: 0;
  background: linear-gradient(to left, orange, tomato);
  transition: 0.5s;
  opacity: .2;
  cursor: pointer !important;
}

.vision-card .card .content {
  z-index: 2;
  position: relative;
}

.vision-card .card:hover .layer {
  top: 0;
}

.vision-card .card .content p {
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin-top: 30px;
  text-align: center;

}

.vision-card .card .content .icon {
  font-size: 3rem;
}

.vision-card .card .conte nt .details h2 {
  font-size: 18px;
  color: white;
}

.vision-card .card:hover .content .details h2 span {
  color: white;
  position: relative
}


.otherhero-text {
  margin-top: 20% !important;
  text-align: justify;
  line-height: 30px;
  letter-spacing: 2px;
  /* 
  
  padding: 0 70px 0 70px; */
}

/* Event image college */

.fBDmlz {
  width: 800px !important;
  /* font-family: Helvetica,Arial,sans-serif; */
  margin: auto;
  margin-bottom: 30px;
}


a#left-tabs-example-tab-first,
a#left-tabs-example-tab-second {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #05044D;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
  color: #000;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.contact-image {
  height: 400px;
  margin-top: 50%;
  margin-left: 10%;
  width: 250px;
  display: block !important;
}
@media screen and (max-width: 960px) {
  .vision-card .card {
    height: 350px;
    cursor: pointer;
    margin-left: -55px;
    width: 300px;
  }

  .Schedule-call {
    font-size: 14px;
  }
  .contact-image {
    display: none !important;
  }
  .text-center {
    font-size: 30px;
  }

  .otherhero-text {
    padding: 20px;
}
 .contact {
  margin-left: -1px !important;
  margin-top: -48px !important;
}
}




.card-link {
  font-weight: 600;
}

.rules-section {
  margin-bottom: 30%;
  margin-top: 5%;
  text-transform: capitalize;
}

.rules-section td {

  text-transform: capitalize;
}

.modal-body-custom {
  max-height: 60vh;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.modal-content-custom {
  white-space: pre-wrap;
  /* Preserve whitespace and wrap long lines */
  font-size: 14px;
  /* Adjust the font size as needed */
  padding: 10px;
  background-color: #f8f9fa;
  /* Light grey background */
  border-radius: 4px;
  border: 1px solid #dee2e6;
}

.modal-content-custom p {
  margin-top: 0%
}

.modal-title {
  font-weight: bold;
  font-size: 1.25rem;
}

.modal-footer .btn {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.clickable-cell {
  cursor: pointer;
}

.contact input[type="text"],
.contact input[type="email"],
.contact input[type="tel"],
.contact input[type="url"],
.contact textarea,
.contact button[type="submit"] {
  font: 400 12px/16px "Open Sans", Helvetica, Arial, sans-serif;
}

.contact {
  background: #f2f2f2;
  padding: 25px;
  margin-left: 30px;
  margin-top: 85px;
}

.contact h3 {
  color: #05044D;
  display: block;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

.contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  text-align: center;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

.contact input[type="text"],
.contact input[type="email"],
.contact input[type="tel"],
.contact textarea {
  width: 100%;
  border: 1px solid #CCC;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

.contact input[type="text"]:hover,
.contact input[type="email"]:hover,
.contact input[type="tel"]:hover,
.contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #AAA;
}

.contact textarea {
  height: 50px;
  max-width: 100%;
  resize: none;
}

.contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #0CF;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

.contact button[type="submit"]:hover {
  background: #09C;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.contact input:focus,
.contact textarea:focus {
  outline: 0;
  border: 1px solid #999;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}